import React from 'react'
import '../../scss/pages/profiles.scss'

import { siteMetadata } from '../../../gatsby-config'
import ProfileLayout from 'components/layout/profiles'
import Meta from 'components/meta'
import peopleData from '../../../content/people.json';

const person = peopleData.people[0];

const Greenspan = ({ data, location }) => (
 <ProfileLayout location={location} profile_name="Brian H. Greenspan" profile_key="greenspan">
   <Meta site={siteMetadata} title="Brian H. Greenspan" />
	 	<div className="row">
			<div className="col-md-9 pr-md-5">
				<h4>About</h4>

				<p>Brian Greenspan is one of the most prominent and respected defence lawyers in Canada. He has served as counsel on many of Canada’s most significant cases that have helped shape criminal law over the past four and a half decades. He has an unparalleled and distinguished reputation in both trial and appellate law.</p>

				<p>A Fellow of the American College of Trial Lawyers, the International Society of Barristers, and Litigation Counsel of America, Brian is sought after for his sage advice in respect of international cross border and government investigations, extradition, mutual legal assistance and criminal trial and appellate advocacy.</p>

				<p>Brian has been recognized by his peers in <i>The International Who’s Who of Business Crime Defence Lawyers</i> and <i>Best Lawyers</i> in Canada since their inception in 2003. He has been named a “Band 1 Leading Individual” in white collar crime  by <i>Chambers Canada</i> since its inaugural edition in 2016 and received the <i>Chambers Canada</i> Lifetime Achievement Award in 2020. He was named in 2010, 2013 and 2018 as one of the 25 Most Influential Lawyers in Canada by <i>Canadian Lawyer</i>.</p>

				<p>He received the Douglas K. Laidlaw Medal for excellence in oral advocacy in 2002 and the G. Arthur Martin Medal for contributions to criminal justice in Canada in 2010.   In 2012, Brian was recognized by The Law Society of Upper Canada with an honorary Doctor of Laws degree. In 2013, he was awarded the Alumni Gold Key for achievement by Osgoode Hall Law School, was selected as one of the Alumni of Influence of University College at the University of Toronto, and was presented with the Key to the City of his hometown, Niagara Falls, Ontario.  He was the recipient of the Toronto Lawyers’ Association Award of Distinction in 2020.</p>

				<p>Brian was the President of the Criminal Lawyers’ Association (Ontario) from 1989 to 1993 and the founding Chair of the Canadian Council of Criminal Defence Lawyers from 1992 to 1996. He is a member of the Ontario Regional Committee of the Supreme Court Advocacy Institute and a member of the Board of Directors of the Innocence Canada Foundation.</p>

				<p>Brian received his B.A. from the University of Toronto in 1968 and his LL.B. from Osgoode Hall Law School in 1971. He was awarded the Laidlaw Foundation Fellowship and received his LL.M. from the London School of Economics in 1972. He was called to the bar in 1974. He taught The Administration of Criminal Justice at Osgoode Hall Law School for seven years and was a special lecturer in Criminal Law at the Faculty of Law, University of Toronto Law School for fourteen years. He has lectured extensively as a keynote speaker and panelist at criminal law continuing education programs and law schools across Canada. Most recently, he was the Bora Laskin Distinguished Lecturer at the Faculty of Law, Lakehead University in 2018 and held the Milvain Chair in Advocacy at the University of Calgary in 2020.</p>

			</div>
			<div className="col-md-3 contact-card">
				<h4 className="text-primary">Get in touch</h4>
				<p className="text-primary">15 Bedford Rd.<br/>
				Toronto, Ontario <br/>
				M5R 2J7</p>

				<p><a className="d-flex align-items-center" href={"tel:"+person.phone_code}><img className="icon pr-1" src="/img/icons/blue-phone.png"/>{person.phone}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.email}><img className="icon pr-1" src="/img/icons/blue-email.png"/>{person.email}</a></p>
				<p><a className="d-flex align-items-center" href={"mailto:"+person.assistant_contact}><img className="icon" src="/img/icons/blue-whatsapp.png"/>Contact {person.short_name}’s Assistant</a></p>
				{/* <p><a className="d-flex align-items-center" ><img className="icon" src="/img/icons/blue-twitter.png"/>Follow {person.short_name} on Twitter</a></p> */}

			</div>
		</div>	 
 </ProfileLayout>
)
export default Greenspan
